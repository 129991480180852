import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css
import './assets/css/uiverse.css'
import Layui from '@layui/layui-vue'
import '@layui/layui-vue/lib/index.css'



createApp(App)
    .use(store)
    .use(router)
    .use(Layui)
    .use(vue3videoPlay)
    .mount('#app')
