import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

const routes = [

  {
    path: '/',
    name: 'Home',
    meta: { title: '首页' },
    component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue')
  },
  {
    path: '/discover',
    name: 'Discover',
    meta: { title: '发现' },
    component: () => import(/* webpackChunkName: "about" */ '../views/discover/index.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: { title: '关于' },
    component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue')
  },
  {
    path: '/vodplay',
    name: 'VodPlay',
    meta: { title: '播放' },
    component: () => import(/* webpackChunkName: "about" */ '../views/vodplay/index.vue')
  },
  {
    path: '/videolist',
    name: 'VideoList',
    meta: { title: '视频列表' },
    component: () => import(/* webpackChunkName: "about" */ '../views/videolist/index.vue')
  }
]

const router = createRouter({
  history:createWebHashHistory(),
  routes
})

router.afterEach((to) => {
  document.title = "梦华云 -" + to.meta.title
})

export default router
