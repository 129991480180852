<template>
  <div>
    <footer>
      <div class="container">
        <p>&copy; 2024 - 2025 梦华云. All rights reserved. |
          <a href="https://beian.miit.gov.cn/" target="_blank">备案号: 津ICP备2023003641号</a> |
          <a href="mailto:xibin@mhuanet.com">联系方式: xibin@mhuanet.com</a>
        </p>
      </div>
    </footer>
  </div>
</template>
<script setup>

</script>
<style scoped>
footer {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
  clear: both;
}

footer a {
  color: #fff;
  text-decoration: none;
}

footer a:hover {
  color: #ccc;
}

</style>