<template>
  <lay-config-provider :theme="theme">
  <lay-layout>
    <Header/>
    <lay-body>
        <router-view/>
    </lay-body>
    <lay-footer>
      <Footer/>
    </lay-footer>
  </lay-layout>
  </lay-config-provider>
</template>

<script setup>
import {ref, watch, onMounted} from 'vue'
import Header from '@/views/layout/Header.vue'
import Footer from "@/views/layout/Footer.vue";
const theme = ref('light')


</script>
<style>

:root {
  --el-color-primary: green;
}

html,body,#app{
  margin: 0;
  padding: 0;
  height: 100%;

}


</style>
